.review-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.review-us-paper {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 10vw;
    padding: 20px;
    text-align: center;
}

.review-us-paper>* {
    margin: 20px;
}